<template>
  <div>
    <MainHeader />
    <Calculator />
    <Categories />
    <HotDeals :hotDeals="products.package" />
    <PayWithSpecta />
    <CategoryProducts
      :loading="loading"
      category="solar panel"
      :products="products.solarPanel"
    />
    <CategoryProducts
      :loading="loading"
      category="inverter"
      :products="products.inverter"
    />
    <MakeRequest />
    <CategoryProducts
      :loading="loading"
      category="battery"
      :products="products.battery"
    />
    <CategoryProducts
      :loading="loading"
      category="bundle"
      :products="products.bundle"
    />
    <CategoryProducts
      :loading="loading"
      category="Accessory"
      :products="products.accessories"
    />
    <CategoryProducts
      :loading="loading"
      category="home equipment"
      :products="products.homeEquipment"
    />
    <!-- <CategoryProducts
      :loading="loading"
      category="preowned"
      :products="products.preowned"
    /> -->
  </div>
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import Calculator from "@/components/Calculator.vue";
import Categories from "@/components/Categories.vue";
import HotDeals from "@/components/HotDeals.vue";
import PayWithSpecta from "@/components/PayWithSpecta.vue";
import MakeRequest from "@/components/MakeRequest.vue";
import CategoryProducts from "@/components/CategoryProducts.vue";

export default {
  data() {
    return {
      loading: true,
      products: {
        solarPanel: [],
        inverter: [],
        battery: [],
        bundle: [],
        package: [],
        accessories: [],
        preowned: [],
        homeEquipment: [],
      },
    };
  },
  components: {
    MainHeader,
    Calculator,
    Categories,
    HotDeals,
    PayWithSpecta,
    MakeRequest,
    CategoryProducts,
  },
  mounted() {
    this.getProductsbyCategory("solar panel");
    this.getProductsbyCategory("inverter");
    this.getProductsbyCategory("battery");
    this.getProductsbyCategory("bundle");
    this.getProductsbyCategory("package");
    this.getProductsbyCategory("accessory");
    this.getProductsbyCategory("preowned");
    this.getProductsbyCategory("home equipment");
  },
  methods: {
    getProductsbyCategory(category) {
      this.loading = true;

      let payload = {
        path: `product?category=${category}&per_page=4`,
      };
      this.$store
        .dispatch("getRequest", payload)
        .then((resp) => {
          // console.log(resp);

          var dec = this.$helpers.decrypt(resp.data.data)
          // console.log(dec);

          resp.data = JSON.parse(dec);

          // console.log(resp);
          this.loading = false;
          switch (category) {
            case "solar panel":
              this.products.solarPanel = resp.data.data.result;
              break;
            case "inverter":
              this.products.inverter = resp.data.data.result;
              break;
            case "battery":
              this.products.battery = resp.data.data.result;
              break;
            case "bundle":
              this.products.bundle = resp.data.data.result;
              break;
            case "package":
              this.products.package = resp.data.data.result;
              break;
            case "accessory":
              this.products.accessories = resp.data.data.result;
              break;
            case "home equipment":
              this.products.homeEquipment = resp.data.data.result;
              break;
            case "preowned":
              this.products.preowned = resp.data.data.result;
              break;
            default:
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$toast.info(
              "Product Items",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Product Items",
              "Unable to load products",
              this.$toastPosition
            );
          }
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
